import Client, { Environment, models } from "@/js/lib/membership-client.encore";
import * as Sentry from "@sentry/browser";
import { getTokenOrLogoutIfInvalid } from "@/js/utils/authValidation";

type Subscription = models.Subscription;
type Call = models.Call;

export type State = {
  isActiveSubscriber: boolean;
  subscription: Subscription | null;
  book_a_vet_call: boolean;
  call: Call | null;
  loading: boolean;
  fetchSubscriptionStatus(): Promise<void>;
  init(): void;
};

const { IS_STAGING, customer: themeCustomer } = useTheme();
let customerAccessToken: string | null = null;

// Client initialization
const encoreMembershipClient = new Client(
  Environment(IS_STAGING ? "dutch-staging" : "dutch-prod")
);

async function fetchMembershipData() {
  if (!customerAccessToken || !themeCustomer?.id) return;

  try {
    const response =
      await encoreMembershipClient.memberships.GetMembershipByShopifyCustomerID(
        themeCustomer?.id.toString(),
        {
          HeaderAuthorization: customerAccessToken,
        }
      );
    return response;
  } catch (error: any) {
    Sentry.captureException(error);
  }
}

export const subscriptionStore: State = {
  isActiveSubscriber: false,
  subscription: null,
  book_a_vet_call: false,
  call: null,
  loading: false,

  async init() {
    customerAccessToken = await getTokenOrLogoutIfInvalid();
    this.fetchSubscriptionStatus();
  },

  async fetchSubscriptionStatus() {
    this.loading = true;

    try {
      const membership = await fetchMembershipData();

      if (membership) {
        this.isActiveSubscriber = membership.active || false;
        this.subscription = membership.subscription || null;
        this.book_a_vet_call = membership.book_a_vet_call || false;
        this.call = membership.call || null;
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  },
};
